const _MxMeta = {
    metaInfo() {
        return {
            title: this.meta.title,
            meta: [
                {
                    name: 'description',
                    content: this.meta.description
                },
                {
                    itemprop: 'name',
                    property: 'og:title',
                    content: this.meta.title
                },
                {
                    itemprop: 'description',
                    property: 'og:description',
                    content: this.meta.description
                },
                {
                    property: 'og:url',
                    content: window.location.href
                },
                {
                    itemprop: 'image',
                    property: 'og:image',
                    content: 'https://www.sydkidspsych.com.au/img/icons/og-image.jpg'
                },
                {
                    property: 'og:locale',
                    content: 'en_AU'
                },
                {
                    property: 'og:type',
                    content: 'article'
                }
            ],
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: this.$vuetify.breakpoint.smAndDown ? `/img/hero/${this.hero.imgSmall}.webp` : `/img/hero/${this.hero.imgLarge}.webp`,
                    alt: this.hero.alt
                }
            ]
        };
    }
};

export default _MxMeta;
