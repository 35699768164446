<template>
    <div class="hero-image">
        <v-img
            :alt="alt"
            :src="`/img/hero/${src}.webp`"
            lazy-src="/img/icons/favicon-16x16.png"
            :aspect-ratio="aspectRatio"
            gradient="to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.66) 100%"
            :height="calcInnerHeight()"
            width="100%"
            class="hero-image__image"
            eager
        >
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary" />
                </v-row>
            </template>
            <h1 class="hero-image__heading">
                {{ heading }}
            </h1>
        </v-img>
        <v-img
            :alt="alt"
            src="/img/clouds.png"
            height="auto"
            width="1920px"
            class="hero-image__clouds"
        />
    </div>
</template>

<script>
export default {
    name: 'HeroImage',
    props: {
        alt: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: true
        },
        imgSmall: {
            type: String,
            required: true
        },
        imgLarge: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            aspectRatio: 1,
            src: ''
        };
    },
    created() {
        this.calcImgSrc();
    },
    methods: {
        calcImgSrc() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.src = this.imgSmall;
                this.aspectRatio = 0.75;
            } else {
                this.src = this.imgLarge;
                this.aspectRatio = 1.7778;
            }
        },
        calcInnerHeight() {
            return window.innerHeight - 56;
        }
    }
};
</script>

<style scoped lang="scss">
.hero-image {
    position: relative;
    &__image {
        max-height: calc(100vh - 56px);
    }
    &__heading {
        animation: h1 $timing-slowest $easing;
        bottom: 25%;
        color: $white;
        font-display: swap;
        font-family: $heading-font-family;
        font-size: 44px;
        font-weight: 500;
        left: 50%;
        line-height: $line-height-sm;
        margin: 0;
        position: absolute;
        text-align: center;
        text-shadow: 1px 2px 0 var(--v-primary-base);
        text-transform: uppercase;
        transform: translateX(-50%);
        width: 95%;
        @media only screen and (max-width: 320px) {
            font-size: 38px;
        }
        @media only screen and (min-width: #{map-get($grid-breakpoints, "sm")}) {
            font-size: 64px;
            font-weight: 400;
            text-shadow: 1px 3px 0 var(--v-primary-base);
            width: 80%;
        }
        @media only screen and (min-width: #{map-get($grid-breakpoints, "md")}) and (max-width: #{map-get($grid-breakpoints, "lg") - 1}) {
            font-size: 84px;
        }
        @media only screen and (min-width: #{map-get($grid-breakpoints, "lg")}) and (max-width: #{map-get($grid-breakpoints, "xl") - 1}) {
            font-size: 104px;
        }
        @media only screen and (min-width: #{map-get($grid-breakpoints, "xl")}) {
            font-size: 124px;
        }
    }
    &__clouds {
        bottom: 0;
        left: 50%;
        max-width: 100%;
        position: absolute;
        transform: translateX(-50%);
    }
}
</style>
