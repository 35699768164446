const _MxAppViewClass = {
    mounted() {
        document.body.classList.add(`app-${this.$options.name}`);
    },
    destroyed() {
        document.body.classList.remove(`app-${this.$options.name}`);
    }
};

export default _MxAppViewClass;
